<template>
  <div style="border: 1px solid #ccc;">
    <Toolbar
        style="border-bottom: 1px solid #ccc"
        :editor="editor"
        :defaultConfig="toolbarConfig"
        :mode="mode"
    />
    <Editor
        @input="inputData"
        style="height: 320px; overflow-y: hidden;"
        v-model="value"
        :defaultConfig="editorConfig"
        :mode="mode"
        @onCreated="onCreated"
    />
  </div>
</template>

<script>
import {Editor, Toolbar} from '@wangeditor/editor-for-vue'
import '@wangeditor/editor/dist/css/style.css'

export default {
  name: "RichEditor",
  components: {
    Editor,
    Toolbar
  },
  props:{
    value:{
      default:""
    }
  },
  data() {
    return {
      mode: 'default',
      editor: null,
      toolbarConfig: {
        excludeKeys: [
          'group-video',
          'group-image'
          , "group-more-style"
          , "fontFamily"
        ]
      },
      editorConfig: {placeholder: '请输入内容...'},
    }
  },
  methods: {
    inputData(val){
      this.$emit("input",val)
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
  }
}
</script>

<style scoped>

</style>